@charset "UTF-8";
/* DEMO ONLY! */
.teaser-img1 {
  background-image: url("../../img/sample-start1.png");
}

.teaser-img2 {
  background-image: url("../../img/sample-start2.png");
}

.teaser-img3 {
  background-image: url("../../img/sample-start3.png");
}

.teaser-img4 {
  background-image: url("../../img/sample-start4.png");
}

.teaser-img5 {
  background-image: url("../../img/sample-start5.png");
}

.teaser-n-img1 {
  background-image: url("../../img/sample-news1.png");
}

.teaser-n-img2 {
  background-image: url("../../img/sample-news2.png");
}

.teaser-n-img3 {
  background-image: url("../../img/sample-news3.png");
}

.teaser-n-img4 {
  background-image: url("../../img/sample-news4.png");
}

.teaser-n-img5 {
  background-image: url("../../img/sample-news5.png");
}

.fzg-img1 {
  background-image: url("../../img/sample-fzg1.png");
}

.fzg-img2 {
  background-image: url("../../img/sample-fzg2.png");
}

.fzg-img3 {
  background-image: url("../../img/sample-fzg3.png");
}

.fzg-img4 {
  background-image: url("../../img/sample-fzg4.png");
}

.fzg-img5 {
  background-image: url("../../img/sample-fzg5.png");
}

.fzg-img6 {
  background-image: url("../../img/sample-fzg6.png");
}

.fzg-img7 {
  background-image: url("../../img/sample-fzg7.png");
}

.fzg-d-img1 {
  background-image: url("../../img/sample-fzg-d1.png");
}

.fzg-d-img2 {
  background-image: url("../../img/sample-fzg-d2.png");
}

.fzg-d-img3 {
  background-image: url("../../img/sample-fzg-d3.png");
}

.fzg-d-img4 {
  background-image: url("../../img/sample-fzg-d4.png");
}

.fzg-d-img5 {
  background-image: url("../../img/sample-fzg-d5.png");
}

/* END DEMO ONLY! */
/* ----------------------------------------

 copyright 2014 by MMCAGENTUR

 Version: 1

 Author: David Rerych, Marion Lesch, Christoph Weinhapl, Thomas Zirkler

 Author URI: http://www.mmc-agentur.at

 ------------------------------------------ */
/* ----------------------------------------
 [Table of contents]

  -) CSS reset
    -) Base
    -) Links
    -) Typography
    -) Lists
    -) Images
    -) Forms
  -) global Classes
    -) Clearfix
  -) text Formating
  -) Table-Styling
  -) Error Pages

 ------------------------------------------ */
/* ------------------------------
 * CSS reset
 * ------------------------------*/
/* -------- Base -------- */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
  height: 100%;
}

body {
  background: #eaeaea;
  font-size: 15px;
  /* 100% = 1em = 16px */
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased;
}

.no-scroll {
  overflow: hidden !important;
  height: 100% !important;
}

body,
button,
input,
select,
textarea {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  color: #494949;
}

/* -------- Links -------- */
a, a:visited {
  color: #e0071b;
  text-decoration: none;
}

a:hover, a:focus, a:active {
  outline: 0;
  color: #e0071b;
  text-decoration: none;
  border-bottom: thin dotted #e0071b;
}

/* -------- Typography -------- */
p {
  margin: 1em 0;
}

blockquote {
  margin: 1em 20px;
}

hr {
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #ccc;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
}

q {
  quotes: none;
}

q:before, q:after {
  content: '';
  content: none;
}

/* -------- Lists -------- */
dl,
menu,
ol,
ul {
  margin: 1em 0;
}

menu,
ol,
ul {
  padding: 0 0 0 15px;
}

dd {
  margin: 0 0 0 15px;
}

nav ul,
nav ol,
.nav ul,
.nav ol {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-top: 0;
  margin-bottom: 0;
}

/* -------- Images -------- */
img {
  vertical-align: middle;
}

/* -------- Forms -------- */
form {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  white-space: normal;
  -webkit-margin-collapse: separate;
}

form ul {
  margin: 0;
  padding: 0;
  list-style: none outside none;
}

label {
  cursor: pointer;
}

table button,
table input {
  overflow: auto;
}

textarea {
  vertical-align: top;
  resize: vertical;
}

/*Remove Spinning-Buttons for Type=Number and Type=Date if it is possible*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  margin: 0;
}

/*Remove Input-Highlighting if it is possible*/
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/* ------------------------------
 * global Classes
 * ------------------------------*/
.ir {
  position: absolute;
  top: -9999px;
  left: 0;
  width: 1px;
  height: 1px;
  margin: 0;
}

.ir br {
  display: none;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.hidden-mobile {
  display: none !important;
  visibility: hidden;
}

@media only screen and (min-width: 700px) {
  .lfv .hide-mobile {
    display: block;
    visibility: visible;
  }
}
.clear {
  clear: both;
}

.ce_clear {
  float: none;
  width: 100%;
  height: 0;
  clear: both;
  overflow: hidden;
  line-height: 0;
  font-size: 1%;
}

/* -------- Clearfix -------- */
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* ------------------------------
 * text formating
 * ------------------------------*/
h1 {
  margin: 1rem;
  font-size: 2rem;
}

h2 {
  margin: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
}

h3 {
  margin: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
}

h4 {
  font-size: 1.2rem;
  text-align: left;
}

h5 {
  font-size: 1rem;
  text-align: left;
}

strong {
  font-weight: bold;
}

italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.atsign {
  background-color: #fa2712;
}

.indent {
  margin-left: 30px;
}

.align--right {
  text-align: right;
}

.align--left {
  text-align: left;
}

.align--center {
  text-align: center;
}

/* ------------------------------
 * Table-Styling
 * ------------------------------*/
.content table {
  margin-bottom: 20px;
  border: 0 none;
}

.content table td,
.content table th {
  padding: 5px 7px;
  border-right: 1px solid #fff;
}

.content table th {
  background-color: #ddd;
  font-weight: bold;
  color: #333;
}

.tr-even {
  background: #f0f0f0;
}

/* ------------------------------
 * Error Pages
 * ------------------------------*/
.content_404 {
  padding: 60px 0 20px 220px;
  background: url(../img/background/404_hasselhoff.png) 0 0 no-repeat;
}

.content_404 p {
  font-size: 1.3em;
  line-height: 1.5em;
}

.lfv .wrapper {
  width: 100%;
}
.lfv > .flex-container {
  padding-top: 60px;
}
.lfv .main-container {
  order: 2;
  z-index: 1;
}
.lfv .nav-container {
  width: 100%;
  order: 1;
  z-index: 500;
}
.lfv main {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
}
.lfv .content-container {
  background-color: white;
}
.lfv .content--twocol {
  margin-top: 1rem;
}
@media only screen and (min-width: 700px) {
  .lfv .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .lfv .content--twocol {
    margin-top: 0.5rem;
  }
  .lfv .content--twocol .flex-container > div:not(.content--element-head) {
    width: 50%;
  }
  .lfv .content--twocol .flex-container > div:first-child {
    padding-right: 20px;
  }
  .lfv .content--twocol .flex-container > div:last-child {
    padding-left: 20px;
  }
  .lfv .content--twocol .flex-container > div:only-child {
    padding-right: 0;
    padding-left: 0;
  }
  .lfv .content--twocol .cycle-slideshow:first-child img {
    margin-right: 20px;
  }
  .lfv .content--twocol .cycle-slideshow:last-child img {
    margin-left: 20px;
  }
  .lfv .content--twocol .cycle-slideshow:only-child img {
    margin-right: 20px;
    margin-left: 0;
  }
}
@media only screen and (min-width: 900px) {
  .lfv .content--twocol {
    margin-top: 1.75rem;
  }
}
@media only screen and (min-width: 1025px) {
  .lfv > .flex-container {
    max-width: 1600px;
    padding-top: 0;
  }
  .lfv .nav-container {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 240px;
    z-index: 122;
  }
  .lfv nav {
    display: block;
  }
  .lfv .nav--navbox {
    display: block;
  }
  .lfv .nav--iconbox {
    display: block;
  }
  .lfv .main-container {
    flex: 1;
    flex-direction: column;
    padding-left: 240px;
    clear: both;
    background-color: white;
  }
  .lfv .start-site .teaser-row:first-of-type {
    position: relative;
    margin-top: auto;
  }
  .lfv .start-site .teaser-row:first-of-type .teaser:not(.content--element-head) {
    max-height: 310px;
  }
  .lfv .content-container {
    position: relative;
    display: block;
    top: auto;
    border-top: none;
  }
}

/* ==========================================================================
     Normalize.scss settings
     From: https://github.com/appleboy/normalize.scss
     ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
     ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *  user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Remove default margin.
 */
body {
  margin: 0;
}

/* HTML5 display definitions
     ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}

/* Links
     ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
/* Text-level semantics
     ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
     ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
     ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
     ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *  (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
     ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/* ==========================================================================
   Print styles
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
.lfk-breadcrumbs-wrapper {
  display: none;
  width: 100%;
}

.lfk-breadcrumbs {
  list-style: none;
  overflow: hidden;
  display: inline-block;
}
.lfk-breadcrumbs > li {
  float: left;
}
.lfk-breadcrumbs > li:after {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 1rem;
  margin-left: 1rem;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid #363636;
}
.lfk-breadcrumbs > li:last-child:after {
  border: none;
}

@media only screen and (min-width: 1025px) {
  .lfk-breadcrumbs-wrapper {
    display: block;
  }
}
.cycle-pager {
  width: 100%;
  position: absolute;
  bottom: 1rem;
  overflow: hidden;
  z-index: 101;
  text-align: center;
  font-size: 1rem;
}
.cycle-pager > span {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.5rem;
  text-indent: -999999rem;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
}
.cycle-pager .cycle-pager-active {
  background-color: white;
}

.cycle-slideshow {
  overflow: hidden;
}

.lfv .content--twocol .flex-container > div.cycle-slideshow {
  max-width: 50%;
}

.slick {
  width: 100%;
  position: absolute;
  bottom: 1rem;
  overflow: hidden;
  z-index: 101;
  text-align: center;
  font-size: 1rem;
}
.slick > span {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.5rem;
  text-indent: -999999rem;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
}
.slick .cycle-pager-active {
  background-color: white;
}

.cycle-slideshow {
  overflow: hidden;
}

.lfv .content--twocol .flex-container > div.cycle-slideshow {
  max-width: 50%;
}

.header--visual {
  position: relative;
  min-height: 310px;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
}
.header--visual:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 122;
  backface-visibility: hidden;
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
  width: 11px;
  height: 11px;
  vertical-align: middle;
  background-image: url("../../img/icons/icon-scroll.png");
  background-size: 11px 11px;
  background-repeat: no-repeat;
}

.header--visual-item {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.header--visual-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 50%);
}

.header--visual-txtbox {
  position: absolute;
  bottom: 4rem;
  left: 1rem;
  right: 1rem;
  z-index: 3;
  max-width: 310px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
}
.header--visual-txtbox span {
  display: block;
}
.header--visual-txtbox h2 {
  margin: 0;
  line-height: 1;
  font-size: 2.3rem;
  font-weight: 600;
}
.header--visual-txtbox h3 {
  margin: 0;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
}

@media only screen and (min-width: 540px) {
  .header--visual-txtbox {
    max-width: none;
  }
}
@media only screen and (min-width: 700px) {
  .header--visual {
    position: relative;
    min-height: 300px;
    max-height: 780px;
  }
  .header--visual:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 122;
    backface-visibility: hidden;
    width: 21px;
    height: 33px;
    vertical-align: middle;
    background-image: url("../../img/icons/icon-mouse.png");
    background-size: 21px 33px;
    background-repeat: no-repeat;
  }

  .header--visual-txtbox {
    bottom: 8rem;
    left: 3rem;
    line-height: 4rem;
    letter-spacing: 1px;
  }
  .header--visual-txtbox h3 {
    font-size: 1.8rem;
    font-style: italic;
  }
  .header--visual-txtbox h2 {
    font-size: 2.8rem;
  }
}
@media only screen and (min-width: 1025px) {
  .header--visual {
    min-height: 460px;
  }

  .header--visual-txtbox h3 {
    font-size: 2rem;
  }
  .header--visual-txtbox h2 {
    font-size: 3.2rem;
  }
}
.ie9 img[src$=".svg"] {
  width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}
.icon-arrow-red, .icon-arrow-red-nav {
  position: relative;
  padding-left: 13px;
}
.icon-arrow-red:before, .icon-arrow-red-nav:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #e0071b;
}

.icon-arrow-red-nav:before {
  left: auto;
  right: 30px;
}

.icon-lock:before {
  content: "";
  display: inline-block;
  width: 19px;
  height: 23px;
  margin-right: 1rem;
  vertical-align: bottom;
  background-image: url("../../img/icons/icon-lock.png");
  background-size: 19px 23px;
  background-repeat: no-repeat;
}

.icon-phone:before {
  content: "";
  display: inline-block;
  width: 43px;
  height: 43px;
  margin-right: 5px;
  vertical-align: middle;
  background-image: url("../../img/icons/icon-phone.png");
  background-size: 43px 43px;
  background-repeat: no-repeat;
}

.icon-fb:before {
  content: "";
  display: inline-block;
  width: 31px;
  height: 31px;
  vertical-align: middle;
  background-image: url("../../img/icons/icon-fb.png");
  background-size: 31px;
  background-repeat: no-repeat;
}

.icon-search:before {
  content: "";
  display: inline-block;
  width: 31px;
  height: 31px;
  vertical-align: middle;
  background-image: url("../../img/icons/icon-search.png");
  background-size: 31px;
  background-repeat: no-repeat;
}

.icon-shop:before {
  content: "";
  display: inline-block;
  width: 31px;
  height: 31px;
  vertical-align: middle;
  background-image: url("../../img/icons/icon-shop.png");
  background-size: 31px;
  background-repeat: no-repeat;
}

.icon-login:before {
  content: "";
  display: inline-block;
  width: 31px;
  height: 31px;
  vertical-align: middle;
  background-image: url("../../img/icons/icon-login.png");
  background-size: 31px;
  background-repeat: no-repeat;
}

.icon-logoff:after {
  content: "";
  display: inline-block;
  width: 31px;
  height: 31px;
  vertical-align: middle;
  background-image: url("../../img/icons/icon-logoff.png");
  background-size: 31px;
  background-repeat: no-repeat;
}

.icon-download:after {
  content: "";
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-left: 10px;
  vertical-align: middle;
  background-image: url("../../img/icons/icon-download.png");
  background-size: 11px 11px;
  background-repeat: no-repeat;
}

.icon-lfv:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 14px;
  width: 35px;
  height: 35px;
  margin-left: 0.8rem;
  vertical-align: middle;
  background-image: url("../../img/lfvnoe_logo.png");
  background-repeat: no-repeat;
  background-size: 35px 35px;
}

@media only screen and (min-width: 1025px) {
  .icon-lfv:before {
    width: 77px;
    height: 74px;
    background-size: 77px 74px;
  }

  .icon-phone:before {
    width: 33px;
    height: 33px;
    background-size: 33px 33px;
  }

  .icon-arrow-red-nav:before {
    display: none;
  }
}
.infobox {
  flex: 1;
  overflow: hidden;
  padding: 30px 20px;
}
.infobox ul {
  display: inline-block;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  line-height: 2em;
  list-style: none;
}
.infobox h4 {
  margin-top: 0;
}
.infobox:first-child {
  background-color: #f2f2f2;
}
.infobox:last-child {
  background-color: #e5e5e5;
}

.infobox--twocol {
  padding: 30px 20px;
}
.infobox--twocol div {
  display: flex;
  text-align: left;
  flex-direction: column;
}
.infobox--twocol div ul {
  flex: 1;
}

.infobox-contact .infobox-contact-wrap {
  height: 177px;
  padding: 0;
  margin-top: 1rem;
  background-color: #f2f2f2;
}
.infobox-contact .infobox-contact-img {
  float: right;
  margin-left: 1rem;
}
.infobox-contact .infobox-contact-img img {
  margin: 0;
  max-width: 8rem;
}
.infobox-contact .infobox-contact-body {
  padding: 1rem;
}
.infobox-contact .infobox-contact-body h3 {
  margin-left: 0;
}
.infobox-contact .infobox-contact-body p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.infobox-contact .infobox-contact-body p a {
  padding-left: 0.5rem;
}

@media only screen and (min-width: 500px) {
  .infobox--twocol div {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1025px) {
  .infobox {
    padding: 40px 50px;
  }

  .infobox--twocol {
    padding: 40px 50px;
  }
}
/* magnificPopup customizations / overrides */
.lfv .lightbox > .teaser-img {
  cursor: pointer;
  cursor: hand;
}
@media only screen and (min-width: 1025px) {
  .lfv .mfp-container {
    left: 240px;
  }
}

.lfv .logonbox {
  display: block;
  padding: 20px 30px;
  border-top: 3px solid red;
  background-color: white;
}
.lfv .logonbox h4 {
  display: block;
}
.lfv .logonbox form {
  display: block;
  flex: 1;
}
.lfv .logonbox form input[type="text"], .lfv .logonbox form input[type="password"] {
  display: block;
  width: 100%;
  height: 44px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding-left: 15px;
  border: 1px solid #cfcfcf;
}
.lfv .logonbox form .btn-triangle {
  display: inline-block;
  position: relative;
  border: 0;
  text-align: left;
  height: 44px;
  background-color: transparent;
  width: 100%;
}
.lfv .logonbox form .btn-triangle span {
  display: none;
}
.lfv .logonbox form .btn-triangle input {
  width: 100%;
  height: 44px;
  cursor: pointer;
  border: none;
  text-indent: -999999em;
  background-color: white;
}
.lfv .logonbox form .btn-triangle:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 9px solid #e0071b;
}
@media only screen and (min-width: 770px) {
  .lfv .logonbox {
    display: flex;
    align-items: center;
  }
  .lfv .logonbox form {
    margin-left: 15px;
  }
  .lfv .logonbox form input[type="text"], .lfv .logonbox form input[type="password"] {
    display: inline-block;
    width: 40%;
    margin-bottom: 0;
  }
  .lfv .logonbox form .btn-triangle {
    width: 12%;
  }
}
.lfv #logonbox-popup {
  position: relative;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  border-top: none;
  max-width: 400px;
  background-color: transparent;
}
.lfv #logonbox-popup form {
  margin-left: 0;
}
.lfv #logonbox-popup form input[type="text"], .lfv #logonbox-popup form input[type="password"] {
  width: 100%;
  margin-bottom: 1rem;
}
.lfv #logonbox-popup form .shake {
  background-color: rgba(240, 200, 200, 0.85) !important;
  border-color: white;
  color: white;
}
.lfv #logonbox-popup form .btn-triangle {
  width: 100%;
}
.lfv #logonbox-popup form .btn-triangle:after {
  left: 7px;
}
.lfv #logonbox-popup form .btn-triangle span {
  display: inline-block;
  margin-left: 20px;
}
.lfv #logonbox-popup form .btn-triangle input {
  text-indent: 0;
  text-align: left;
  padding-left: 2rem;
  color: #363636;
  background-color: transparent;
}
.lfv #logonbox-popup .mfp-close {
  top: 25px;
  right: 15px;
}
.lfv .response {
  display: none;
  text-align: center;
  width: 100%;
  height: 44px;
  text-align: center;
}
@media only screen and (min-width: 1025px) {
  .lfv .response {
    display: inline-block;
    display: none;
    position: relative;
    width: 12%;
    height: 44px;
    background-color: transparent;
  }
}

.nav-container {
  position: fixed;
  width: 100%;
  height: 61px;
  top: -1px;
  z-index: 1;
  transition: top 0.5s ease;
  color: white;
  background-color: #e0071b;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  /* the new scrollbar will have a flat appearance with the set background color */
  /* this will style the thumb, ignoring the track */
  /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */
  /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
}
.nav-container ::-webkit-scrollbar-track {
  background-color: #e0071b;
}
.nav-container ::-webkit-scrollbar-thumb {
  background-color: #c30012;
}
.nav-container ::-webkit-scrollbar-button {
  background-color: #c30012;
}
.nav-container ::-webkit-scrollbar-corner {
  background-color: #c30012;
}
.nav-container a, .nav-container a:hover, .nav-container a:active, .nav-container a:visited {
  color: white;
  border-bottom: none;
}
.nav-container .nav--headline {
  background-color: #e0071b;
}
.nav-container .nav--headline h1 {
  overflow: hidden;
  display: inline-block;
  padding-left: 4rem;
  line-height: 3;
  white-space: nowrap;
  font-weight: 600;
  font-size: 0.8rem;
}
.nav-container .nav--headline hr {
  margin: 10px 0;
}
.nav-container .nav--headline a {
  display: inline-block;
}
.nav-container .nav-mobile {
  display: block;
  position: fixed;
  overflow: hidden;
  width: 100%;
  top: 60px;
  bottom: 0;
  left: 100%;
  right: 0;
  transition: left 0.5s ease;
  background-color: #e0071b;
  border-top: 1px solid white;
}
.nav-container .nav-mobile.nav-mobile--visible {
  left: 0;
}
.nav-container nav {
  position: absolute;
  width: 100%;
  top: 12px;
  bottom: 0;
  transition: top 0.5s ease;
  z-index: 22;
  background-color: #e0071b;
}
.nav-container nav ul {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: top 0.5s ease;
  text-transform: none;
  overflow-y: scroll;
}
.nav-container nav ul li.expand {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 22;
}
.nav-container nav ul li.expand > a, .nav-container nav ul li.expand > a:active, .nav-container nav ul li.expand > a:focus {
  color: white;
  font-size: 1.3rem;
  background-color: #c30012;
  text-transform: uppercase;
}
.nav-container nav ul li.expand > a:before, .nav-container nav ul li.expand > a:active:before, .nav-container nav ul li.expand > a:focus:before {
  display: none;
}
.nav-container nav ul li.expand > ul {
  display: block;
  top: 45px;
}
.nav-container nav a {
  display: block;
  padding-left: 1.7rem;
  line-height: 2.85rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  text-decoration: none;
}
.nav-container nav a:active {
  background-color: #c30012;
}
.nav-container nav a:focus {
  background-color: #c30012;
}
.nav-container nav > ul {
  display: block;
}
.nav-container nav .nav--top > li > a {
  text-transform: uppercase;
}
.nav-container nav .nav--mid {
  background-color: rgba(220, 219, 221, 0.95);
}
.nav-container nav .nav--mid a {
  font-size: 1rem;
  color: #323232;
}
.nav-container nav .nav--mid a:active {
  background-color: rgba(205, 200, 200, 0.95);
}
.nav-container nav .nav--mid a:focus {
  background-color: rgba(205, 200, 200, 0.95);
}
.nav-container nav .nav--sub {
  background-color: rgba(254, 252, 252, 0.95);
}
.nav-container nav .nav--sub a:active, .nav-container nav .nav--sub a:focus {
  background-color: rgba(245, 240, 240, 0.95);
}
.nav-container nav .nav--sub .icon-arrow-red-nav:before {
  right: 45px;
}

.nav-up {
  top: -60px;
}

nav.nav-expanded {
  top: 45px !important;
}

.nav-expanded {
  top: 0 !important;
  z-index: 31 !important;
  overflow-y: hidden !important;
}

.nav--back a {
  display: block;
  padding-left: 2rem;
  font-size: 1rem;
  font-weight: 100;
  line-height: 2.85rem;
  text-decoration: none;
}

.nav--navbox {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  background-color: #c30012;
}

.navbox--head {
  position: relative;
  max-width: 262px;
  margin: 10px auto 0;
}
.navbox--head .nummer {
  display: inline-block;
  margin-right: 9px;
  font-size: 3.625rem;
  font-weight: 700;
  vertical-align: middle;
}
.navbox--head .notruf-wrap {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
}
.navbox--head .notruf-wrap .zeit {
  font-size: 1.25rem;
  line-height: 28px;
}
.navbox--head .notruf-wrap .notruf {
  font-size: 1.875rem;
}

.navbox--body {
  display: none;
}

.navbox--icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 262px;
  margin: 0 auto 10px;
  background-color: #c30012;
  color: #bababa;
}
.navbox--icons .icon {
  display: inline-block;
}

.nav--logon-state {
  display: none;
  opacity: 0;
  position: fixed;
  bottom: 0;
  width: 240px;
  height: 70px;
  z-index: 23;
  background-color: #343434;
}
.nav--logon-state .txt-box {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
.nav--logon-state .txt-box a {
  display: inline-block;
  width: 10rem;
  overflow: hidden;
  vertical-align: middle;
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nav--logon-state .txt-box a.icon-logoff:after {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  background-size: 2rem;
}
.nav--logon-state .txt-box span {
  font-size: 1rem;
  font-weight: bold;
}

.burger {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  padding-top: 18px;
  padding-left: 20px;
  cursor: pointer;
  z-index: 100;
}
.burger span {
  content: '';
  position: absolute;
  display: block;
  top: 51%;
  border-radius: 1px;
  height: 1px;
  width: 25px;
  background: white;
}
.burger span:before {
  content: '';
  position: absolute;
  display: block;
  top: -8px;
  border-radius: 1px;
  height: 1px;
  width: 25px;
  background: white;
}
.burger span:after {
  content: '';
  position: absolute;
  display: block;
  bottom: -8px;
  border-radius: 1px;
  height: 1px;
  width: 25px;
  background: white;
}

@media only screen and (max-height: 420px) and (max-width: 1025px) {
  .nav-container .navbox--icons {
    display: none;
  }
}
@media only screen and (max-height: 500px) and (max-width: 1025px) {
  .nav-container .navbox--head {
    display: none;
  }
  .nav-container .navbox--icons {
    padding: 15px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1025px) {
  .burger {
    display: none;
  }

  .nav-container {
    height: auto;
  }
  .nav-container .nav--headline {
    width: 240px;
    height: 140px;
  }
  .nav-container .nav--headline h1 {
    overflow: visible;
    white-space: normal;
    padding-left: 100px;
    margin-top: 1.2rem;
    font-size: 1.33rem;
    line-height: 1.33rem;
  }
  .nav-container .nav-mobile {
    display: block;
    overflow: visible;
    position: fixed;
    width: 240px;
    min-height: 310px;
    top: 140px;
    transform: none;
    bottom: auto;
    left: auto;
    right: auto;
    border-top: none;
  }
  .nav-container nav {
    top: 0;
    height: 350px;
  }
  .nav-container nav ul {
    position: static;
    overflow: visible;
    right: 0;
    padding-right: 0;
  }
  .nav-container nav ul li > ul {
    position: fixed;
    width: 320px;
    height: 100vh;
    top: 0;
    padding-top: 140px;
    box-shadow: 7px 0 23px -4px rgba(0, 0, 0, 0.1);
  }
  .nav-container nav ul li.desk-expand > ul {
    display: block;
  }
  .nav-container nav a {
    font-size: 1.25rem;
    font-weight: normal;
    padding-left: 1.3rem;
    line-height: 2.5rem;
  }
  .nav-container nav .nav--top {
    padding-right: 0;
    text-transform: uppercase;
  }
  .nav-container nav .nav--top .desk-expand:hover {
    position: relative;
    background-color: #c80517;
    color: white;
  }
  .nav-container nav .nav--top .desk-expand:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    z-index: 22;
    right: 0px;
    top: 50%;
    transform: translate(6px, -50%);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #c30012;
  }
  .nav-container nav .nav--mid {
    left: 240px;
  }
  .nav-container nav .nav--mid li a {
    display: inline-block;
  }
  .nav-container nav .nav--mid li a:hover {
    color: #e0071b;
  }
  .nav-container nav .nav--mid li:hover:after {
    border-left: 6px solid rgba(205, 200, 200, 0.95);
  }
  .nav-container nav .nav--mid .desk-expand {
    position: relative;
    background-color: rgba(205, 200, 200, 0.95);
  }
  .nav-container nav .nav--mid .desk-expand:hover {
    background-color: rgba(205, 200, 200, 0.95);
  }
  .nav-container nav .nav--mid .desk-expand:after {
    border-left: 6px solid rgba(205, 200, 200, 0.95);
  }
  .nav-container nav .nav--mid .desk-expand > a {
    color: #e0071b;
  }
  .nav-container nav .nav--sub {
    left: 560px;
  }
  .nav-container nav .nav--sub li:hover {
    color: #e0071b;
    background-color: rgba(245, 240, 240, 0.95);
  }
  .nav-container nav .nav--sub li:hover:after {
    border: 0;
  }
  .nav-container nav .nav--sub li a {
    display: block;
  }

  .nav--navbox {
    position: fixed;
    bottom: 0;
    width: 240px;
    min-height: 310px;
    background-color: #c30012;
  }

  .is-logged-in .nav--navbox {
    bottom: 70px;
    min-height: 240px;
  }
  .is-logged-in .nav--logon-state {
    display: block;
    opacity: 1;
  }

  .navbox--head {
    margin-bottom: 12px;
    padding: 10px 20px 0;
  }
  .navbox--head .nummer {
    font-size: 2.8em;
    font-weight: 600;
  }
  .navbox--head .notruf-wrap .zeit {
    font-size: 0.9375rem;
    line-height: normal;
  }
  .navbox--head .notruf-wrap .notruf {
    font-size: 1.375rem;
    font-weight: 100;
  }
  .navbox--head hr {
    margin-top: 12px;
  }

  .navbox--body {
    display: block;
    margin: 0 20px;
    font-size: 14px;
  }
  .navbox--body span:first-child {
    font-weight: 600;
  }

  .navbox--icons {
    padding: 20px;
    margin: 0;
  }
}
@media only screen and (max-height: 730px) and (min-width: 1025px) {
  .is-logged-in .nav-container .nav--navbox {
    height: auto;
    min-height: auto;
  }
  .is-logged-in .nav-container .navbox--body {
    display: none;
  }
}
@media only screen and (max-height: 700px) and (min-width: 1025px) {
  .nav-container .nav--navbox {
    height: auto;
    min-height: auto;
  }
  .nav-container .navbox--body {
    display: none;
  }
}
@media only screen and (max-height: 600px) and (min-width: 1025px) {
  .is-logged-in .nav-container .navbox--head {
    display: none;
  }
}
@media only screen and (max-height: 520px) and (min-width: 1025px) {
  .nav-container .navbox--head {
    display: none;
  }
}
@media only screen and (max-height: 490px) and (min-width: 1025px) {
  .is-logged-in .navbox--icons {
    display: none;
  }
}
progress {
  /* Positioning */
  position: fixed;
  left: 0;
  top: 0;
  /* Dimensions */
  width: 100%;
  height: .25em;
  /* Reset the apperance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Get rid of the default border in Firefox/Opera. */
  border: none;
  /* For Firefox/IE10+ */
  background-color: transparent;
  /* For IE10+, color of the progress bar */
  color: red;
}

progress::-webkit-progress-bar {
  background-color: transparent;
}

.flat::-webkit-progress-value {
  background-color: red;
}

.flat::-moz-progress-bar {
  background-color: red;
}

.progress-container {
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: .25em;
  display: block;
}

.progress-bar {
  background-color: red;
  width: 50%;
  display: block;
  height: inherit;
}

.teaser-row {
  width: 100%;
}

.teaser {
  min-height: 210px;
  padding: 1.25rem;
  display: block;
  flex-direction: column;
  justify-content: flex-end;
  word-wrap: break-word;
  background-color: #d0d0d0;
}
.teaser:nth-child(even) {
  background-color: #eaeaea;
}
.teaser .teaser--date {
  font-size: 0.8rem;
  display: block;
  margin-bottom: 0.5rem;
}
.teaser h2 {
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
.teaser p {
  margin: 1.5rem 0;
}

.teaser-slide {
  width: 100%;
  height: 100%;
}

.teaser-img {
  position: relative;
  min-height: 210px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: white;
}
.teaser-img h2 {
  margin-bottom: 1rem;
}
.teaser-img a {
  color: white;
}
.teaser-img a:hover {
  color: #e0071b;
  border-bottom: none;
}
.teaser-img * {
  z-index: 10;
}

.teaser-headline {
  position: relative;
  width: 100%;
  line-height: 1.4rem;
  background-color: white;
  text-align: center;
}
.teaser-headline h3 {
  margin: 0.3rem;
  line-height: 1;
  font-size: 1.3rem;
  font-style: italic;
  font-weight: 600;
  text-transform: uppercase;
}
.teaser-headline h2 {
  margin: 0;
  line-height: 1;
  font-size: 1.733rem;
  text-transform: uppercase;
}
.teaser-headline p {
  margin: 2.5rem 0 0 0;
}

.teaser--txtbox {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  right: 1.25rem;
  z-index: 11;
  text-shadow: 0px 0px 6px black;
}
.teaser--txtbox p {
  display: none;
}

.teaser-img-txt:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 50%);
}

.teaser-grisu {
  padding: 10px;
  align-items: center;
  justify-content: center;
  background-color: #555;
}
.teaser-grisu .grisu-map {
  width: 100%;
  height: 100%;
}
.teaser-grisu .grisu-map-wrapper {
  z-index: 1;
  position: absolute;
  top: 1.25rem;
  bottom: 1.25rem;
  left: 1.25rem;
  right: 1.25rem;
}

.teaser--arrows .teaser-img:last-child:not(.teaser-img-txt):after {
  content: "";
  width: 0px;
  height: 0px;
  margin: 0 auto;
  border-top: 13px solid #d0d0d0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.teaser--arrows .teaser-img:first-child:not(.teaser-img-txt):after {
  content: "";
  width: 0px;
  height: 0px;
  margin: 0 auto;
  border-bottom: 13px solid #eaeaea;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.teaser-devided .teaser-row > div {
  border-bottom: 2px solid red;
}

@media only screen and (min-width: 700px) {
  .teaser--arrows .teaser-img:first-child:not(.teaser-img-txt):after {
    content: "";
    width: 100%;
    height: 0px;
    margin: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-right: 13px solid #eaeaea;
    z-index: 2;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .teaser--arrows .teaser-img:last-child:not(.teaser-img-txt):after {
    content: "";
    width: 0px;
    height: 0px;
    margin: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 13px solid #d0d0d0;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .teaser-devided .teaser-row > div:nth-last-child(n+2) {
    border-right: 2px solid red;
  }

  .teaser-headline {
    justify-content: center;
    padding: 2.5rem;
  }
  .teaser-headline h2 {
    font-size: 3rem;
  }
  .teaser-headline h3 {
    font-size: 1.667rem;
  }

  .teaser {
    display: block;
    width: 50%;
    min-height: 310px;
    padding: 2.5rem;
  }
  .teaser.teaser-3, .teaser.teaser:nth-child(3) {
    width: 100%;
  }

  .teaser:not(.teaser-3) p {
    overflow: hidden;
    max-height: 75px;
  }

  .teaser--txtbox {
    position: absolute;
    bottom: 2.5rem;
    left: 2.5rem;
    right: 2.5rem;
  }
}
@media only screen and (min-width: 1025px) {
  .teaser-headline {
    padding: 3.75rem 2.5rem;
  }
}
@media only screen and (min-width: 1170px) {
  .teaser-row .teaser {
    display: flex;
  }
  .teaser-row .teaser.teaser-1 {
    width: 33.333%;
    min-width: 310px;
  }
  .teaser-row .teaser.teaser-2 {
    flex: 1;
  }
}
@media only screen and (min-width: 1280px) {
  .teaser--txtbox p {
    display: block;
  }
}
ul.lfk-dienstanweisungen {
  list-style: none;
  padding: 0 0 0 0;
}

.lfk-dienstanweisung-version {
  color: #f00 !important;
}

ul.lfk-dienstanweisungen > li {
  list-style-type: none;
}

li.lfk-item--1 {
  margin-left: 30px;
  font-size: 0.9rem;
  font-style: italic;
}

li.lfk-item-1 span, li.lfk-item-1 a {
  font-weight: bold;
  font-size: 1.1rem;
}

.car-detail .teaser {
  min-height: 212px;
}

.contact-site {
  height: 100vh;
  background-color: white;
}
.contact-site .gmaps--infoWindow {
  font-size: 12px;
  padding: 10px 10px 0;
  width: 100%;
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  color: #494949;
}
.contact-site .gmaps--infoWindow h1 {
  font-size: 1.250em;
  line-height: 1.2;
}
@media only screen and (min-width: 700px) {
  .contact-site .gmaps--infoWindow {
    width: 345px;
    padding: 20px 20px 0;
    font-size: 16px;
  }
}

/* specific content stuff */
.content-site {
  position: relative;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  word-break: break-word;
  text-align: justify;
}
.content-site .content-row {
  width: 100%;
}
.content-site .content--visual {
  height: 100px;
  background-image: url("../../img/sample-content-header.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-bottom: 3px solid red;
}
.content-site article {
  padding: 0 20px 20px;
}

/* generic content stuff */
.lfv .content--element-head {
  position: relative;
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 2rem;
}
.lfv .content--element-head h3 {
  margin: 0.3rem;
  line-height: 1;
  font-size: 1.3rem;
  font-style: italic;
  font-weight: 600;
  text-transform: uppercase;
}
.lfv .content--element-head h2 {
  margin: 0;
  line-height: 1;
  font-size: 1.733rem;
  text-transform: uppercase;
}
.lfv .content--element-head p {
  margin: 2.5rem 0 0 0;
}
.lfv article > .content--element-head {
  padding: 2rem 0 0 0;
}
.lfv .content--element-text, .lfv .content--element-img {
  flex: 1;
  text-align: justify;
}
.lfv .content--element-text img, .lfv .content--element-img img {
  width: 100%;
  margin-top: 1rem;
}
.lfv .content--element-text h4, .lfv .content--element-img h4 {
  margin-top: 1rem;
}
.lfv .content--element-text ul, .lfv .content--element-img ul {
  list-style-type: square;
  color: #e0071b;
}
.lfv .content--element-text ul span, .lfv .content--element-img ul span {
  color: #494949;
  line-height: 1;
}
.lfv .content--element-text ul li, .lfv .content--element-img ul li {
  margin-bottom: 0.5rem;
}
.lfv .table .table-row {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
}
.lfv .table .table-row:nth-of-type(even) {
  background-color: #d0d0d0;
}
.lfv .table .table-row:nth-of-type(odd) {
  background-color: #eaeaea;
}
.lfv .table .table-row .table-col {
  display: block;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 1rem;
}
.lfv .table .table-row .table-col:first-child {
  font-weight: bold;
  padding-bottom: 0;
}
.lfv .table .table-row .table-col:last-child {
  flex: 1;
}
.lfv .content--element-img1 {
  width: 100%;
  min-height: 280px;
  margin: 0 auto;
  background-image: url("../../img/sample-content-img.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.lfv .ul-triangle li {
  position: relative;
  display: table-row;
  border-spacing: 0 0.5rem;
}
.lfv .ul-triangle li:before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 1rem;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid #363636;
}
.lfv .ul-triangle li span {
  display: table-cell;
}
.lfv .btn-red {
  position: relative;
  display: inline-block;
  padding: 15px 20px 15px 40px;
  line-height: normal;
  vertical-align: middle;
  cursor: pointer;
  color: white;
  background-color: #e0071b;
  border-radius: 5px;
  transition: all 0.3s;
}
.lfv .btn-red:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 7px solid white;
}
.lfv .btn-red:hover {
  color: #e0071b;
  background-color: #eaeaea;
}
.lfv .btn-red:hover:before {
  content: "";
  border-left: 7px solid #e0071b;
}
.lfv .center-inline {
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 700px) {
  .content-site article {
    padding: 0 40px 100px;
  }
  .content-site .content--visual {
    height: 140px;
  }

  .lfv .content--element-head {
    justify-content: center;
    padding: 2.5rem 2rem;
  }
  .lfv .content--element-head h2 {
    font-size: 3rem;
    line-height: 1;
  }
  .lfv .content--element-head h3 {
    font-size: 1.667rem;
  }
  .lfv .content--element-text ul span, .lfv .content--element-img ul span {
    line-height: 1.5rem;
  }
  .lfv .content--element-text img, .lfv .content--element-img img {
    margin-top: 2rem;
  }
  .lfv .content--element-text h4, .lfv .content--element-img h4 {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 1025px) {
  .content-site .content--visual {
    height: 280px;
  }

  .lfv .content--element-head {
    padding: 3.75rem 2.667rem;
  }
  .lfv article > .content--element-head {
    padding: 3.75rem 0 0;
  }
  .lfv .table {
    flex-direction: column;
  }
  .lfv .table .table-row {
    flex-direction: row;
  }
  .lfv .table .table-row .table-col:first-child {
    min-width: 160px;
    font-weight: normal;
    padding-bottom: 1rem;
  }
}
.news-site {
  text-align: left;
}
.news-site .content--element-head {
  height: auto;
  min-height: 0;
}
.news-site .center-inline {
  height: 140px;
  line-height: 140px;
}

.lfv .news-detail .teaser {
  min-height: 310px;
}
.lfv .news-detail .content--element-head h3 {
  font-style: normal;
}
.lfv .news-detail article > .content--element-head {
  padding: 3.75rem 2.667rem;
}

@media only screen and (min-width: 1025px) {
  .news-detail article {
    padding-bottom: 50px;
  }
  .news-detail .content--element-head {
    height: auto;
    min-height: 0;
  }
  .news-detail .content--element-head h3 {
    font-style: normal;
  }
}
.site-leben {
  overflow: hidden;
}
.site-leben .leben--jumpnav-stripes .jumpnav--element {
  position: relative;
  width: 100%;
  height: 45px;
  border-bottom: 1px solid white;
  background-position-y: 32%;
}
.site-leben .leben--jumpnav-stripes .jumpnav--element:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 80%);
}
.site-leben .leben--jumpnav-stripes .jumpnav--element .txtbox {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}
.site-leben .leben--jumpnav-stripes .jumpnav--element .txtbox h2 {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.site-leben .leben--quotes {
  position: relative;
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 2rem;
}
.site-leben .leben--quotes p {
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.6;
  font-style: italic;
}
.site-leben .leben--section-visual {
  height: 140px;
  border-bottom: 3px solid #e0071b;
  background-repeat: no-repeat;
  background-size: cover;
}
.site-leben .leben--section-headline {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 2rem 2rem 0;
}
.site-leben .leben--section-headline h3 {
  margin: 0.3rem;
  line-height: 1;
  font-size: 1rem;
  font-style: italic;
  font-weight: 600;
}
.site-leben .leben--section-headline h2 {
  margin: 0;
  line-height: 1;
  font-size: 1.333rem;
}
.site-leben .leben--section-headline p {
  margin: 2.5rem 0 0 0;
}
.site-leben .leben--section-body {
  padding: 0 2rem 2rem;
}
.site-leben .leben--section-element {
  align-items: center;
  margin-top: 2rem;
}
.site-leben .leben--section-element > div:last-child {
  padding-top: 1rem;
}
.site-leben .leben--section-element .content--element-img {
  position: relative;
  width: 100%;
}
.site-leben .leben--section-element {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.site-leben .leben--section-element .content--element-text {
  order: 2;
}
.site-leben .leben--section-element .content--element-img {
  margin-bottom: 2rem;
  order: 1;
}
.site-leben .leben--section-element .content--element-img .img-icon {
  position: absolute;
  width: auto;
  height: 80px;
  left: 2rem;
  bottom: -2rem;
  opacity: 0;
}
.site-leben .leben--section-element .content--element-img:first-child .img-picture {
  transform: translateX(-100%);
  opacity: 0;
}
.site-leben .leben--section-element .content--element-img:first-child .img-icons {
  transform: translateX(-300%);
  opacity: 0;
}
.site-leben .leben--section-element .content--element-img:last-child .img-picture {
  transform: translateX(100%);
  opacity: 0;
}
.site-leben .leben--section-element .content--element-img:last-child .img-icons {
  transform: translateX(300%);
  opacity: 0;
}

@media only screen and (min-width: 400px) {
  .site-leben .leben--jumpnav-stripes {
    line-height: 0;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element {
    position: relative;
    display: inline-block;
    width: 50%;
    height: 100px;
    background-position-y: 32%;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element .txtbox {
    position: absolute;
    top: auto;
    transform: none;
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element .txtbox h2 {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 1.2rem;
    color: white;
    text-align: right;
    text-transform: uppercase;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element:nth-child(odd) {
    border-right: 1px solid white;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element:nth-child(3) {
    border-top: 1px solid white;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element:nth-child(4) {
    border-top: 1px solid white;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 60%);
  }
}
@media only screen and (min-width: 540px) {
  .site-leben .leben--jumpnav-stripes .jumpnav--element {
    width: 25%;
    height: 150px;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element:nth-child(2) {
    border-right: 1px solid white;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element:nth-child(3) {
    border-top: none;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element:nth-child(4) {
    border-top: none;
  }
}
@media only screen and (min-width: 700px) {
  .site-leben .leben--jumpnav-stripes .jumpnav--element {
    height: 200px;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element .txtbox {
    right: 2rem;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element .txtbox h2 {
    font-size: 1.5em;
  }
  .site-leben .leben--quotes {
    padding: 2.5rem 2rem;
  }
  .site-leben .leben--section-kid:before {
    background: url("/assets/img/leben/bg-kid.jpg");
  }
  .site-leben .leben--section-youth:before {
    background: url("/assets/img/leben/bg-youth.jpg");
  }
  .site-leben .leben--section-active:before {
    background: url("/assets/img/leben/bg-active.jpg");
  }
  .site-leben .leben--section-reserve:before {
    background: url("/assets/img/leben/bg-reserve.jpg");
  }
  .site-leben .leben--section {
    position: relative;
  }
  .site-leben .leben--section:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.1;
    background-position: 100% 100%;
    background-size: cover;
  }
  .site-leben .leben--section-headline h2 {
    font-size: 1.8rem;
  }
  .site-leben .leben--section-element {
    margin-top: 2.5rem;
  }
  .site-leben .leben--section-element {
    flex-direction: row;
  }
  .site-leben .leben--section-element .content--element-text {
    order: 1;
  }
  .site-leben .leben--section-element .content--element-img {
    order: 1;
  }
  .site-leben .leben--section-element .content--element-img .img-icon, .site-leben .leben--section-element .content--element-img .icon-truck {
    width: auto;
    height: auto;
  }
}
@media only screen and (min-width: 1025px) {
  .site-leben .leben--jumpnav-stripes .jumpnav--element {
    height: 220px;
  }
  .site-leben .leben--jumpnav-stripes .jumpnav--element .txtbox h2 {
    font-size: 1.8rem;
  }
  .site-leben .leben--quotes {
    padding: 5rem;
  }
  .site-leben .leben--section-body {
    padding: 0 120px 120px;
  }
  .site-leben .leben--section-headline {
    padding: 4.4rem 2rem 0;
  }
  .site-leben .leben--section-headline h2 {
    font-size: 2.2rem;
  }
  .site-leben .leben--section-element {
    margin-top: 3.75rem;
  }
  .site-leben .leben--section-element .content--element-img:first-child .img-icons {
    transform: translateX(-300%);
    opacity: 0;
  }
  .site-leben .leben--section-element .content--element-img:last-child .img-icons {
    transform: translateX(300%);
    opacity: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .site-leben .leben--jumpnav-stripes .jumpnav--element {
    height: 260px;
  }
}
.search-site {
  padding: 0 20px 20px;
}
.search-site article {
  margin: 30px 0;
}
.search-site article:first-of-type {
  margin: 0;
}
.search-site .search--imgwrap {
  display: none;
}
.search-site .search--txtwrap h2 {
  margin: 0;
}
@media only screen and (min-width: 700px) {
  .search-site .search--imgwrap {
    display: inline-block;
    width: 265px;
    float: left;
    height: 160px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
  }
  .search-site .search--imgwrap img {
    height: 160px;
  }
  .search-site .search--txtwrap {
    margin-left: 295px;
  }
  .search-site .search--noimg {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .search-site {
    padding: 0 40px 100px;
  }
  .search-site .search--txtwrap {
    padding-top: 1rem;
  }
  .search-site article {
    margin: 60px 0;
  }
}

#search-popup {
  padding: 0 2rem;
}
#search-popup input[type="text"] {
  width: 100%;
  padding: 1rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #3f3f3f;
  color: #3f3f3f;
  font-size: 2rem;
}
#search-popup input[type="text"]:after {
  content: "";
  width: 10px;
  height: 10px;
  background-image: url("../../img/icons/icon-search.png") no-repeat;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url('../../img/ajax-loader.gif?1448810274') center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url('../../fonts/slick.eot?1448810274');
  src: url('../../fonts/slick.eot?&1448810274#iefix') format("embedded-opentype"), url('../../fonts/slick.woff?1448810274') format("woff"), url('../../fonts/slick.ttf?1448810274') format("truetype"), url('../../fonts/slick.svg?1448810274#slick') format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px\9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

@media only screen and (min-width: 1025px) {
  .no-js .nav-container nav ul li:hover > ul {
    display: block;
  }
}
/**
 * From compass
 */
/**
 * Mixins
 */
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  40% {
    -moz-transform: translate(-50%, -20px);
    transform: translate(-50%, -20px);
  }
  60% {
    -moz-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  40% {
    -webkit-transform: translate(-50%, -20px);
    transform: translate(-50%, -20px);
  }
  60% {
    -webkit-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  40% {
    -moz-transform: translate(-50%, -20px);
    -ms-transform: translate(-50%, -20px);
    -webkit-transform: translate(-50%, -20px);
    transform: translate(-50%, -20px);
  }
  60% {
    -moz-transform: translate(-50%, -10px);
    -ms-transform: translate(-50%, -10px);
    -webkit-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px);
  }
}
input.shake {
  -webkit-animation: shake .1s linear 0s 4;
  animation: shake .1s linear 0s 4;
}

@-webkit-keyframes shake {
  0% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
}
@keyframes shake {
  0% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
}
/**
 * Flexbox
 */
/**
 * Bootstrap components
 *
 * If you want to use bootstrap in your project remove the comment
 */
/**
 * html5 boilerplate
 */

/*# sourceMappingURL=master.css.map */
